import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // To dispatch actions
import { Box, Button, Container } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetMenuQuery } from "../../../features/menuSlice";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { setMenuLoadingComplete } from "../../../features/spinnerSlice"; // Import the actions
import { Spinner } from "../../../components/index"; // Full page spinner

const SubMenu = ({ childrens, language }) => {
  const { t } = useTranslation(); // Initialize t function for translation
  return (
    <ul className="subItem">
      {childrens.map((item) => (
        <li key={item.id} style={{ listStyleType: "none" }}>
          <NavLink to={item?.link} className="subItemText">
            {item?.title?.[language]}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
export const Header = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation(); // Initialize t function for translation

  const language = location.pathname.split("/")[1] || "en";

  const { data: menus, error, isLoading, isFetching } = useGetMenuQuery();

  useEffect(() => {
    // Dispatch when menu loading is complete
    dispatch(
      setMenuLoadingComplete({
        value: !isLoading,
        meta: { pathname: location.pathname },
      })
    );
  }, [isLoading, dispatch]);

  if (error) return <p>Error loading menu</p>;
  const headerMenu = menus?.[0];

  return (
    <>
      <Spinner fullPage={true} /> {/* Show full-page spinner if needed */}
      <Box className={styles.root}>
        <Container maxWidth="xl" className={styles.container}>
          <Box
            className={styles.image}
            onClick={() => navigate(`/${language}/home`)}
          >
            <img src="/images/MLOps.svg" alt={t("HEADER.LOGO_ALT")} />
          </Box>
          <Box className={styles.tabBox}>
            {headerMenu?.children?.map((item) => (
              <li key={item.id} className={styles.menuStyle}>
                <Button
                  onClick={() => {
                    const link = item?.link;
                    if (
                      link.startsWith("http://") ||
                      link.startsWith("https://")
                    ) {
                      window.location.href = link; // Full URL
                    } else {
                      navigate(`/${language}${link}`); // Prepend language
                    }
                  }}
                  className={styles.button}
                >
                  <span>{item?.title?.[language]}</span>
                  {item?.children?.length > 0 && <ExpandMoreIcon />}
                </Button>
                {item?.children?.length > 0 && (
                  <SubMenu
                    navigate={navigate}
                    childrens={item?.children}
                    language={language}
                  />
                )}
              </li>
            ))}
            {/*             <Button variant="contained" className={styles.btn}>
              {t("HEADER.LOGIN")}
            </Button> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Header;
