import { createSlice } from "@reduxjs/toolkit";

// Initial state for global loading
const initialState = {
  isFullPageLoading: true, // Initially, we're loading
  menuCompleted: false, // Track if the menu has completed loading
  mainCompleted: false, // Track if the main content has completed loading

  currentPath: "", // Track the current path
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setMenuLoadingComplete(state, action) {
      console.log("Menu loading action dispatched:", action.payload);

      // Update menuCompleted based on the action payload
      state.menuCompleted = action.payload.value;

      const isHomePath =
        state.currentPath === "/ru/home" || state.currentPath === "/en/home";

      // Determine if the loader should stop based on the current path
      if (isHomePath) {
        state.isFullPageLoading = !(state.menuCompleted && state.mainCompleted);
      } else {
        state.isFullPageLoading = !state.menuCompleted;
      }
    },
    setMainLoadingComplete(state, action) {
      console.log("Main loading action dispatched:", action.payload);

      // Update mainCompleted status
      state.mainCompleted = action.payload;

      // Ensure the loader logic checks for the current path
      const isHomePath =
        state.currentPath === "/ru/home" || state.currentPath === "/en/home";
      if (isHomePath) {
        state.isFullPageLoading = !(state.menuCompleted && state.mainCompleted);
      }
    },

    updateCurrentPath(state, action) {
      console.log("Path changed to:", action.payload);

      // Update the current path
      const newPath = action.payload;
      state.currentPath = newPath;

      // Reset relevant loading states based on the new path
      if (newPath === "/ru/home" || newPath === "/en/home") {
        // Reset mainCompleted for home paths
        if (!state.mainCompleted) state.isFullPageLoading = true; // Loader should depend on menu and main for home
      }
    },
  },
});

export const {
  setMenuLoadingComplete,
  setMainLoadingComplete,

  updateCurrentPath,
} = loadingSlice.actions;

export default loadingSlice.reducer;
