import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    gap: "32px",
    display: "flex",
    flexDirection: "column",
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
}));

export default useStyles;
