import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(91.42deg, #234A9B 0%, #3E69C1 100%)",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkBox: {
    alignItems: "center",
    display: "flex",
    gap: "8px",
  },
  link: {
    color: `${theme.globals.colors.white}!important`,
    fontSize: `${theme.globals.fontSize.s}px!important`,
    textDecoration: "none",
  },
  rights: {
    textAlign: "center",
    paddingTop: "8px",
    color: theme.globals.colors.white,
    borderTop: `1px solid ${theme.globals.colors.gray}`,
    fontSize: `${theme.globals.fontSize.s}px!important`,
  },
}));
export default useStyles;
