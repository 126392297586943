import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed", // To cover the whole screen
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    backdropFilter: "blur(5px)", // Blurred background effect
    display: "flex", // Flexbox for centering
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    zIndex: 9999, // Ensure it's on top of other content
  },
  blockRoot: {
    position: "relative", // Position it relative to the block
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    zIndex: 10, // Ensure it's above the content of the block
  },
}));

export default useStyles;
