import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import useStyles from "./styles";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";

export default function Middle({ post, isLoading, isError }) {
  const classes = useStyles();
  const { id } = useParams(); // Extract the post ID from the URL
  const type = "model"; // Set the type as 'model'

  // Sanitize the description using DOMPurify
  const sanitizedDescription = post.description?.ru
    ? DOMPurify.sanitize(post.description.ru)
    : "";

  return (
    <Box className={classes.container}>
      {/* Render the post title */}
      <Typography variant="h4" className={classes.mainTitle} gutterBottom>
        {post.title?.ru || "Default Title"}
      </Typography>

      {/* Render the sanitized description */}
      <Typography
        variant="body1"
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      />
    </Box>
  );
}
