// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./rootReducer"; // Import the root reducer
import { authApi } from "./features/authSlice";
import { configurationApi } from "./features/configurationsSlice";

import { userApi } from "./features/userApi";
import { menuApi } from "./features/menuSlice";
import { sliderApi } from "./features/sliderApi";
import { postApi } from "./features/postApi";
import { useLocation } from "react-router-dom";
export const attachPathNameToAction = (store) => (next) => (action) => {
  if (action.meta && action.meta.pathname) {
    action.pathname = action.meta.pathname; // Attach pathname from action's meta
    console.log("Middleware: Pathname attached to action:", action.pathname); // Log the pathname
  } else {
    console.log("Middleware: No pathname in action meta");
  }
  return next(action); // Pass action to the next middleware or reducer
};
// Function to load state from localStorage
const loadState = () => {
  try {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (user === null || token === null) {
      return undefined;
    }
    return { user: { user: JSON.parse(user), token: JSON.parse(token) } };
  } catch (err) {
    return undefined;
  }
};

const preloadedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState, // Use preloadedState
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware) // Add the middleware for RTK-Query API
      .concat(menuApi.middleware) // Add the middleware for RTK-Query API
      .concat(sliderApi.middleware) // Add the middleware for RTK-Query API
      .concat(postApi.middleware) // Add the middleware for RTK-Query API

      .concat(configurationApi.middleware) // Add the middleware for RTK-Query API

      .concat(userApi.middleware) // Add the middleware for RTK-Query API
      .concat(attachPathNameToAction),
});

setupListeners(store.dispatch); // Set up listeners for cache invalidation and refetching

export default store;
