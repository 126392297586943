import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base query with the base URL
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || "http://localhost:7999/api",
});

// Define the API slice
export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery,
  endpoints: (builder) => ({
    // Request to get all posts by type
    getPostsByType: builder.query({
      query: (type) => ({
        url: `post`,
        method: "GET",
        params: {
          type, // Example: type=news
        },
      }),
      transformResponse: (response) => {
        return response?.posts;
      },
      providesTags: (result, error, type) => [{ type: "PostList", id: type }],
    }),

    // Request to get a single post by id and type
    getPostById: builder.query({
      query: ({ id, type }) => ({
        url: `post/${id}`, // Example: http://localhost:7999/api/post/123
        method: "GET",
        params: {
          type, // Example: type=news
        },
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: (result, error, { id, type }) => [
        { type: "Post", id: `${type}-${id}` },
      ],
    }),
    getPostByAlias: builder.query({
      query: ({ alias }) => ({
        url: `post/${alias}`, // Example: http://localhost:7999/api/post/123
        method: "GET",
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: (result, error, { alias }) => [{ type: "Post", alias }],
    }),
  }),
});

// Export hooks for the queries
export const {
  useGetPostsByTypeQuery,
  useGetPostByIdQuery,
  useGetPostByAliasQuery,
} = postApi;

// Export the reducer and middleware for store configuration
export const { reducerPath, reducer, middleware } = postApi;
