import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Button, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "./../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../features/userSlice";
import { createUseStyles } from "react-jss";
import i18n from "i18next"; // Import the background image

const useStyles = createUseStyles({
  loginContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginBox: {
    backgroundColor: "#ffffff",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  loginTitle: {
    fontSize: "24px",
    marginBottom: "10px",
    fontWeight: 600,
    color: "#333",
    textAlign: "left",
  },
  loginHeading: {
    textAlign: "left",
    fontSize: "28px",
    marginBottom: "30px",
    fontWeight: 700,
    color: "#333",
  },
  loginButton: {
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
    color: "#fff",
    fontWeight: 600,
    width: "100%",
    height: "50px",
    fontSize: "16px",
  },
  forgotPasswordLink: {
    fontSize: "14px",
    color: "#888",
    marginTop: "10px",
  },
  signupText: {
    marginTop: "20px",
    color: "#888",
  },
  signupLink: {
    fontWeight: 600,
    color: "#ffc107",
    paddingLeft: "5px",
  },
});

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const user = useSelector((state) => state.user);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const dispatch = useDispatch();
  const classes = useStyles(); // Initialize the useStyles hook
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    if (user?.user?.id) navigate(`/${i18n.language}`);
  }, [user, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setNotification({
        open: true,
        message: "Invalid email format",
        type: "error",
      });
      return;
    }
    try {
      const response = await loginUser({
        email: formData.email,
        password: formData.password,
      }).unwrap();

      if (response.success) {
        dispatch(setUser({ user: response.user, token: response.token }));
        setNotification({
          open: true,
          message: "Login successful!",
          type: "success",
        });
        // Delay navigation to allow the notification to display properly
        setTimeout(() => {
          navigate("/projects");
        }, 1000); // 1 second delay before navigation
      } else {
        setNotification({
          open: true,
          message: response.message,
          type: "error",
        });
      }
    } catch (error) {
      setNotification({
        open: true,
        message: "Invalid email or password",
        type: "error",
      });
    }
  };

  return (
    <div className={classes.loginContainer}>
      <Grid
        container
        justifyContent="center"
        style={{ width: "100%" }}
        alignItems="center"
      >
        <Grid item xs={11} sm={8} md={6} lg={4}>
          <div className={classes.loginBox}>
            <Typography variant="h4" className={classes.loginTitle}>
              {t("login.welcomeBack")} 👋
            </Typography>
            <Typography variant="h5" className={classes.loginHeading}>
              {t("login.signInToYourAccount")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label={t("login.email")}
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <TextField
                label={t("login.password")}
                name="password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                className={classes.loginButton}
                fullWidth
              >
                {isLoading ? t("login.loggingIn") : t("login.continue")}
              </Button>
              <Button
                type="button"
                className={classes.forgotPasswordLink}
                onClick={() => navigate("/forgot-password")}
              >
                {t("login.forgotPassword")}
              </Button>
            </form>
            <Typography className={classes.signupText}>
              {t("login.noAccount")}{" "}
              <Button
                type="button"
                className={classes.signupLink}
                onClick={() => navigate("/signup")}
              >
                {t("login.signUp")}
              </Button>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        message={notification.message}
        action={
          <Button color="inherit" onClick={handleNotificationClose}>
            Close
          </Button>
        }
        severity={notification.type}
      />
    </div>
  );
};

export default Login;
