import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  root: {
    display: "flex !important",
    justifyContent: "center !important",
  },
  title: {
    fontSize: `${theme.globals.fontSize.g}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    marginBottom: "16px!important",
  },
  slider: {
    "& .slick-next": {
      right: "15px",
      top: "25%",
      zIndex: 100,
      "&:before": {
        display: "none",
      },
    },
    "& .slick-prev": {
      left: "15px",
      top: "25%",
      zIndex: 100,
      "&:before": {
        display: "none",
      },
    },
    "& .slick-dots li button:before": {
      color: "#D9D9D9",
    },
    "& .slick-dots li.slick-active button:before": {
      color: " #878789",
    },
  },
  slickPrev: {
    color: "transparent",
    "&:before": {
      content: '"←"',
      color: "green",
      fontSize: "24px",
    },
  },
  slickNext: {
    color: "transparent",
    "&:before": {
      content: '"→"',
      color: "green",
      fontSize: "24px",
    },
  },
}));
export default useStyles;
