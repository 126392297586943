import * as React from "react";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import translation hook

export default function Left({ extractedHeadings }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  // Navigate back to the home page
  const handleGoHome = () => {
    navigate("/ru/home");
  };

  // Smooth scroll to the section when a menu item is clicked
  const handleScrollToSection = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Stack
      className={classes.root}
      direction="column"
      spacing={1}
      sx={{
        width: 350, // Fixed width
        position: "sticky", // Sticky position to stay within its container
        top: 0, // Stick to the top of the viewport
        alignSelf: "flex-start", // Prevent stretching
      }}
    >
      {/* "Back to Home" Button */}
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        className={classes.backButton}
        sx={{
          marginTop: "32px!important",
          backgroundColor: "#1E4DA1", // Blue background color
          color: "#FFFFFF", // White text color
          borderRadius: "8px", // Rounded corners
          paddingX: 2, // Horizontal padding
          textTransform: "none", // Remove uppercase transformation
        }}
        onClick={handleGoHome}
      >
        {t("LEFT.backToHome")}
      </Button>

      {/* Menu Title */}
      <Typography className={classes.title} variant="h6" sx={{ padding: 2 }}>
        {t("LEFT.gettingStarted")}
      </Typography>

      {/* Menu List */}
      <Paper className={classes.paper}>
        <MenuList className={classes.menu}>
          {extractedHeadings.length > 0 ? (
            extractedHeadings.map((heading) => (
              <MenuItem
                key={heading.id}
                onClick={() => handleScrollToSection(heading.id)}
                className={classes.menuItem}
                sx={{
                  overflow: "hidden", // Hide overflowing text
                  whiteSpace: "nowrap", // Prevent text wrapping
                  textOverflow: "ellipsis", // Add ellipsis for truncated text
                }}
              >
                {heading.text}
              </MenuItem>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ padding: 2, color: "#999", textAlign: "center" }}
            >
              {t("LEFT.noTagsAvailable")}
            </Typography>
          )}
        </MenuList>
      </Paper>
    </Stack>
  );
}
