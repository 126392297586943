// features/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";

import { authApi } from "./features/authSlice";

import { configurationApi } from "./features/configurationsSlice";
import { menuApi } from "./features/menuSlice";
import { sliderApi } from "./features/sliderApi";
import { postApi } from "./features/postApi";
import loadingReducer from "./features/spinnerSlice";

// Combine all the reducers
const appReducer = combineReducers({
  authApi: authApi.reducer,
  menuApi: menuApi.reducer,
  sliderApi: sliderApi.reducer,
  postApi: postApi.reducer,
  loading: loadingReducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
});

// Root reducer to handle reset state action
const rootReducer = (state, action) => {
  if (action.type === "resetState") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
