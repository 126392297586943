export const makeTheme = ({ currentTheme }) => {
  return {
    direction: "ltr",
    palette: {
      background: {
        default: "#fff",
      },
      primary: {
        main: currentTheme.elements.colors.black,
      },
      secondary: {
        main: currentTheme.elements.colors.gray,
      },
      black: {
        main: currentTheme.elements.colors.black,
      },
      gray: {
        main: currentTheme.elements.colors.gray,
      },
    },
    globals: { ...currentTheme.elements, direction: "ltr" },
  };
};
