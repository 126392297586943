import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(94.51deg, #234A9B 0%, #3E69C1 100%)",
    borderRadius: "20px",
    minHeight: "466px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  slider: {
    "& .slick-next": {
      right: "90px",
      top: "46%",
      zIndex: 100,
      "&:before": {
        display: "none",
      },
    },
    "& .slick-prev": {
      top: "46%",
      left: "90px",
      zIndex: 100,
      "&:before": {
        display: "none",
      },
    },
  },
  slickPrev: {
    color: "transparent",
    "&:before": {
      content: '"←"',
      color: "green",
      fontSize: "24px",
    },
  },
  slickNext: {
    color: "transparent",
    "&:before": {
      content: '"→"',
      color: "green",
      fontSize: "24px",
    },
  },
  slide: {
    display: "flex !important",
    justifyContent: "center  !important",
    gap: "135px",
  },
  slideContent: {
    maxWidth: "400px",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  image: {
    height: "300px",
    width: "396px",
    "& img": {
      height: "100%",
      maxWidth: "100%",
      borderRadius: "20px",
    },
  },
  slideTitle: {
    fontSize: `${theme.globals.fontSize.xxl}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    color: `${theme.globals.colors.white}!important`,
  },
  slideSummary: {
    fontSize: `${theme.globals.fontSize.m}px!important`,
    color: `${theme.globals.colors.white}!important`,
    maxWidth: "340px",
  },
  slideBtn: {
    borderRadius: "12px!important",
    fontSize: `${theme.globals.fontSize.m}px!important`,
    color: `${theme.palette.primary.main}!important`,
    backgroundColor: `${theme.globals.colors.white}!important`,
    padding: "8px 16px!important",
    marginLeft: "32px!important",
  },
}));
export default useStyles;
