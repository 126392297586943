import { Typography, Box } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useLocation, useNavigate } from "react-router-dom";

const PlatformSlider = ({ data }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const language = location.pathname.split("/")[1] || "en";

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <button className={styles.slickPrev}>
        <img
          src={"/images/platform/left.svg"}
          alt="Previous"
          style={{ width: "18px", height: "36px" }}
        />
      </button>
    ),
    nextArrow: (
      <button className={styles.slickNext}>
        <img
          src={"/images/platform/right.svg"}
          alt="Next"
          style={{ width: "18px", height: "36px" }}
        />
      </button>
    ),
  };

  return (
    <Box className={styles.root}>
      <Slider {...settings} className={styles.slider}>
        {data &&
          data.map((slide, index) => {
            const cardImage = slide.files.find(
              (image) =>
                (language === "en" &&
                  (image.publishMode === 1 || image.publishMode === 4)) ||
                (language === "ru" &&
                  (image.publishMode === 2 || image.publishMode === 4))
            );

            return (
              <Box
                onClick={() => {
                  /*                   const link = JSON.parse(slide?.extraData)?.link?.[language];
                  if (link) {
                    if (
                      link.startsWith("http://") ||
                      link.startsWith("https://")
                    ) {
                      window.location.href = link;
                    } else {
                      navigate(`/${language}${link}`);
                    }
                  } */
                }}
                className={styles.slide}
                key={index}
              >
                <Box className={styles.slideContent}>
                  <Typography
                    className={styles.slideTitle}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {slide.title?.[language] || "Default Title"}
                  </Typography>
                  <Typography
                    className={styles.slideSummary}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {stripHtmlTags(
                      slide.description?.[language] || "Default Description"
                    )}
                  </Typography>
                </Box>
                {cardImage && (
                  <Box className={styles.image}>
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "/file/download/" +
                        cardImage.uuid
                      }
                      alt={cardImage.alt?.[language] || "slide image"}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
      </Slider>
    </Box>
  );
};

export default PlatformSlider;
