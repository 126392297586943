import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {},
  root: {},
  paper: {},
  menu: {},
  title: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    padding: "8px 16px 8px 16px !important",
  },
}));
export default useStyles;
