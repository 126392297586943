import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => {
  return {
    root: {
      marginTop: "10px",
      padding: "0px !important",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "7px",

      marginBottom: "7px",
      marginRight: "25px",
    },
    newsTitle: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      fontSize: `${theme.globals.fontSize.s}px !important`,
      fontWeight: `${theme.globals.fontWeight.l} !important`,
    },
    newsList: {
      border: "1px #cccccc solid",
      borderRadius: "14px",
      overflow: "scroll",
      height: "480px",
    },
  };
});

export default styles;
