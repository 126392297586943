import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    height: "38px",
    width: "38px",
    "& img": {
      maxWidth: "100%",
      height: "100%",
    },
  },
  label: {
    fontSize: `${theme.globals.fontSize.m}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    textAlign: "center",
  },
  info: {
    backgroundColor: theme.globals.colors.gray,
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    width: "300px",
    height: "208px",
    gap: "8px",
    padding: "30px",
  },
  widtherInfo: {
    width: "370px!important",
  },
  summary: {
    fontSize: `${theme.globals.fontSize.sm}px!important`,
    color: `${theme.globals.colors.lightGray}!important`,
    textAlign: "center",
  },
}));
export default useStyles;
