import { makeStyles } from "@mui/styles";
const styles = makeStyles((theme) => {
  return {
    card: {
      marginTop: "15px !important",
    },
    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    cardTitle: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      fontSize: `${theme.globals.fontSize.s}px !important`,
      fontWeight: `${theme.globals.fontWeight.l} !important`,
    },
    cardDate: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      color: "#a5a5a6",
    },
    cardText: {
      marginTop: "4px !important",
      fontFamily: `${theme.globals.fontFamily} !important`,
    },
  };
});
export default styles;
