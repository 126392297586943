import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useDispatch } from "react-redux";
import News from "../../components/home/news";
import Info from "../../components/home/information";
import PlatformSlider from "../../components/home/platformSlider";
import useStyles from "./styles";
import Modules from "../../components/home/modules";
import { useGetSliderQuery } from "../../features/sliderApi";
import { setMainLoadingComplete } from "../../features/spinnerSlice"; // Import actions
import { Spinner } from "../../components/index"; // Full page spinner

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    data: modelData,
    isLoading: modelLoading,
    error: modelError,
  } = useGetSliderQuery("model");
  const {
    data: mainData,
    isLoading: mainLoading,
    error: mainError,
  } = useGetSliderQuery("main");

  useEffect(() => {
    if (!mainLoading) {
      dispatch(setMainLoadingComplete(true)); // Mark main loading complete
    }
  }, [mainLoading, dispatch]);

  const {
    data: infoData,
    isLoading: infoLoading,
    error: infoError,
  } = useGetSliderQuery("information");
  const {
    data: newsData,
    isLoading: newsLoading,
    error: newsError,
  } = useGetSliderQuery("news");

  if (modelError || mainError || infoError || newsError)
    return <p>Error loading sliders</p>;

  return (
    <Box>
      <PlatformSlider data={mainData} />{" "}
      <Container maxWidth="xl" className={classes.container}>
        {newsLoading ? <Spinner fullPage={false} /> : <News data={newsData} />}

        {/* Info Block - Show Spinner while infoData is loading */}
        {infoLoading ? (
          <Spinner fullPage={false} /> // Spinner for Info Block (not full page)
        ) : (
          <Info data={infoData} />
        )}

        {/* Modules Block - Show Spinner while modelData is loading */}
        {modelLoading ? (
          <Spinner fullPage={false} />
        ) : (
          <Modules data={modelData} />
        )}
      </Container>
    </Box>
  );
};

export default Home;
