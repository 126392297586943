import { Typography, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";

const NotFound = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Typography className={styles.title}>404</Typography>
      <Typography className={styles.text}>
        Страница не найдена! <Link to="/ru/home">Перейти на главную.</Link>
      </Typography>
    </Box>
  );
};

export default NotFound;
