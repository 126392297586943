import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  title: {
    fontSize: `${theme.globals.fontSize.g}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    marginBottom: "16px!important",
  },
  image: {
    height: "38px",
    width: "38px",
    "& img": {
      maxWidth: "100%",
      height: "100%",
    },
  },
  label: {
    fontSize: `${theme.globals.fontSize.m}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    textAlign: "center",
  },
  container: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  info: {
    backgroundColor: theme.globals.colors.gray,
    borderRadius: "20px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    width: "300px",
    height: "208px",
    gap: "8px",
    padding: "30px",
  },
}));
export default useStyles;
