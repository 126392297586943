import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 0px 16px 0px #00000014",
  },
  container: {
    height: "66px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  image: {
    width: "86px",
    height: "29px",
    position: "relative",
    top: "4px",
    cursor: "pointer",
    "& img": {
      maxWidth: "100%",
      height: "100%",
    },
  },
  tabBox: {
    display: "flex",
    alignItems: "center",
  },
  menuStyle: {
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
    listStyleType: "none",
    padding: "8px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      height: "50%",
      top: "25%",
      borderRight: `2px solid ${theme.globals.colors.gray}`,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
    "& > .subItem": {
      backgroundColor: `${theme.globals.colors.black}!important`,
      opacity: "0",
      display: "none",
      "& li": {
        padding: "8px 0px!important",
      },
    },
    "& .MuiButton-root": {
      color: `${theme.globals.colors.black}!important`,
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      fontSize: `${theme.globals.fontSize.m}px!important`,
      height: "55px",
      "& >span": {
        color: `${theme.globals.colors.black}!important`,
      },
    },

    "&:hover": {
      "& .MuiButton-root": {
        background: "none!important",
        "& >span": {
          zIndex: "103",
        },
        "&::before": {
          width: "100%",
          height: "100%",
          content: '""',
          background: "no-repeat",
          backgroundSize: "cover",
          left: "0px",
          top: "12px",
          position: "absolute",
          transform: " rotate(180deg)",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          zIndex: 101,
        },
      },
      "& > .subItem": {
        opacity: "1",
        display: "flex!important",
        flexDirection: "column",
        position: "absolute",
        borderRadius: "12px",
        top: "24px!important",
        backgroundColor: "rgba(255,255,255,0.8)!important",
        backdropFilter: "blur(8px)!important",
        padding: "16px",
        boxShadow: "0px 0px 16px 0px #00000014",
        zIndex: "100",
        "& .subItemText": {
          color: `${theme.globals.colors.lightGray}!important`,
          fontSize: `${theme.globals.fontSize.m}px!important`,
          textTransform: "capitalize",
          fontWeight: `${theme.globals.fontWeight.m}!important`,
          fontFamily: theme.globals.fontFamily,
          textDecoration: "none",
        },
      },
    },
  },
  btn: {
    marginLeft: "16px!important",
    backgroundColor: `${theme.globals.colors.darkBlue}!important`,
    height: "42px",
    width: "70px",
    borderRadius: "12px!important",
  },
}));
export default useStyles;
