import React, { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    backend: {
      loadPath: process.env.REACT_APP_TRANS_API,
      parse: function (data, lang, nameSpace) {
        return JSON.parse(data)?.[lang]?.[nameSpace];
      },
    },
    fallbackLng: "ru", // Set default language to "ru"
    ns: ["translation"],
    defaultNS: "translation",
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
  });

const TranslationProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const validLangCodes = ["ru", "en"]; // Add "ru" as a valid language
    const currentLangCode = location.pathname.split("/")[1];

    if (!validLangCodes.includes(currentLangCode)) {
      const localStorageLanguage = localStorage.getItem("lng") || "ru"; // Default to "ru"
      localStorage.setItem("lng", localStorageLanguage);
      navigate(`/${localStorageLanguage}${location.pathname}`, {
        replace: true,
      });
    } else {
      i18n.changeLanguage(currentLangCode);
      localStorage.setItem("lng", currentLangCode);
    }
  }, [location.pathname, navigate]);

  return <>{children}</>;
};

export default TranslationProvider;
