import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(8), // Add space above the footer

    minHeight: "75vh", // Ensure the content spans the full viewport height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "80%",
    marginTop: "20px",
  },
  card: {
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    width: "500px",
    "&:hover": {
      transform: "scale(1.01)",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
    },
  },
  imageDiv: {
    width: "100%",
    height: "300px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#e9ecef",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    textDecoration: "underline",
    marginTop: theme.spacing(2),
    fontSize: "22px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#333",
    "&:hover": {
      transform: "scale(1.01)",

      cursor: "pointer", // Add the pointer cursor on hover
    },
  },
  paginationBox: {
    position: "relative", // Keep it in the flow of the content
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    padding: theme.spacing(2),
    "&  .MuiPagination-root": {
      backgroundColor: "unset",
    },
  },
}));

export default useStyles;
