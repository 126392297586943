import { CssBaseline, Paper } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { jssPreset, StylesProvider, ThemeProvider } from "@mui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import React, { Suspense, useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Header, Footer } from "./components";
import Home from "./pages/home/index";
import Login from "./pages/Login";
import { basicTheme } from "./theme/theme";
import { makeTheme } from "./theme/utils";
import NotFound from "./pages/notFound";
import ContactUs from "./pages/contactUS";
import Basic from "./pages/basic";
import BasicByAlias from "./pages/basicByAlias";
import Download from "./pages/download";
import TranslationProvider from "./TransaltionProvider";
import Spinner from "./components/spinner";
import List from "./pages/list";
import { updateCurrentPath } from "./features/spinnerSlice";
import { useDispatch } from "react-redux";

const AppRouter = () => {
  const theme = makeTheme(basicTheme);
  const componentRef = useRef();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const materialTheme = createTheme(theme);
  const location = useLocation();
  const dispatch = useDispatch();
  const validLangs = ["ru", "en"];

  // Extract the language parameter from the URL
  const lang = location.pathname.split("/")[1];

  useEffect(() => {
    // Dispatch action to update the current path
    dispatch(updateCurrentPath(location.pathname));
  }, [location.pathname, dispatch]);

  // Redirect to a default language (e.g., "ru") if the language is invalid
  if (!validLangs.includes(lang)) {
    return <Navigate to={`/ru${location.pathname}`} replace />;
  }

  const showFooter = ["/home", "/not-found"].some((path) =>
    location.pathname.includes(path)
  );

  return (
    <div>
      <TranslationProvider>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={materialTheme}>
            <CssBaseline />
            <Paper
              ref={componentRef}
              display="flex"
              flexDirection="column"
              width="100%"
              dir="ltr"
              id="full-page"
              style={{
                background: materialTheme.palette.background.default,
                position: "relative",
                minHeight: "100vh",
                height: "100%",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                filter: "inherit",
              }}
            >
              <Suspense fallback={<Spinner fullPage={true} />}>
                <Header />
                <div style={{ flex: 1 }}>
                  <Routes>
                    {/* Default route redirects to /ru/home */}
                    <Route path="/" element={<Navigate to="/ru/home" />} />

                    {/* Redirect /ru, /ru/, /en, /en/ to /lang/home */}
                    {validLangs.map((lang) => (
                      <React.Fragment key={lang}>
                        <Route
                          path={`/${lang}`}
                          element={<Navigate to={`/${lang}/home`} />}
                        />
                        <Route
                          path={`/${lang}/`}
                          element={<Navigate to={`/${lang}/home`} />}
                        />
                      </React.Fragment>
                    ))}

                    {/* Dynamic routes with :lang parameter */}
                    <Route path="/:lang/home" element={<Home />} />
                    <Route path="/:lang/contact-us" element={<ContactUs />} />
                    <Route path="/:lang/login" element={<Login />} />
                    <Route path="/:lang/test" element={<h2>Test Route</h2>} />
                    <Route
                      path="/:lang/model/:id"
                      element={<Download type={"model"} />}
                    />
                    <Route
                      path="/:lang/information/:id"
                      element={<Download type={"information"} />}
                    />
                    <Route path="/:lang/basic" element={<Basic />} />

                    <Route
                      path="/:lang/news/:id"
                      element={<Basic type={"news"} />}
                    />
                    <Route
                      path="/:lang/user-management"
                      element={<h2>User Management</h2>}
                    />
                    <Route
                      path="/:lang/category/news"
                      element={<List type={"news"} />}
                    />
                    <Route
                      path="/:lang/category/model"
                      element={<List type={"model"} />}
                    />
                    <Route
                      path="/:lang/category/information"
                      element={<List type={"information"} />}
                    />
                    <Route
                      path="/:lang/alias/:alias"
                      element={<BasicByAlias />}
                    />

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>

                <Footer />
              </Suspense>
            </Paper>
          </ThemeProvider>
        </StylesProvider>
      </TranslationProvider>
    </div>
  );
};

export default AppRouter;
