import {
  Typography,
  Box,
  Container,
  Button,
  Checkbox,
  InputLabel,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const ContactUs = () => {
  const styles = useStyles();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success", // success, error, warning, info
  });

  const initialValues = {
    name: "",
    email: "",
    question: "",
    accept: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("обязательно"),
    email: Yup.string()
      .email("неверный адрес электронной почты")
      .max(255)
      .required("обязательно"),
    question: Yup.string().required("обязательно"),
    accept: Yup.boolean().oneOf([true], "Необходимо подтвердить согласие"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/service/request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            language: "all",
          },
          body: JSON.stringify({
            serviceId: 2,
            name: values.name,
            email: values.email,
            message: values.question,
          }),
        }
      );

      const responseBody = await response.json();

      if (!responseBody.success) {
        throw new Error("Ошибка на сервере");
      }

      setNotification({
        open: true,
        message: "Ваше сообщение успешно отправлено!",
        severity: "success",
      });
      resetForm();
    } catch (error) {
      setNotification({
        open: true,
        message: "Ошибка отправки: Попробуйте снова.",
        severity: "error",
      });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="xl" className={styles.root}>
      <Typography className={styles.title}>Форма обратной связи</Typography>
      <Typography className={styles.subTitle}>Задайте Ваш вопрос</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(formik) => (
          <Form className={styles.form}>
            <Box>
              <InputLabel htmlFor="name" className={styles.label}>
                Ваше ФИО(полностью)
              </InputLabel>
              <TextField
                type="text"
                id="name"
                name="name"
                variant="outlined"
                className={styles.input}
                onChange={(e) => {
                  formik?.setFieldValue("name", e?.target?.value);
                }}
              />
              {formik.touched.name && formik.errors.name ? (
                <Typography className={styles.error}>
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <InputLabel htmlFor="email" className={styles.label}>
                Ваш e-mail
              </InputLabel>
              <TextField
                type="text"
                id="email"
                name="email"
                variant="outlined"
                className={styles.input}
                onChange={(e) => {
                  formik?.setFieldValue("email", e?.target?.value);
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <Typography className={styles.error}>
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <InputLabel htmlFor="question" className={styles.label}>
                Текст Вашего вопроса
              </InputLabel>
              <textarea
                className={styles.autoSize}
                name="question"
                id="question"
                value={formik?.values?.question}
                rows="8"
                cols="100"
                onChange={(e) => {
                  formik?.setFieldValue("question", e?.target?.value);
                }}
              />
              {formik.touched.question && formik.errors.question ? (
                <Typography className={styles.error}>
                  {formik.errors.question}
                </Typography>
              ) : null}
            </Box>
            <Box display={"flex"} alignItems="flex-start">
              <Checkbox
                onChange={(e, check) => {
                  formik.setFieldValue("accept", check);
                }}
                className={styles.checkBox}
              />
              <Typography className={styles.checkText}>
                Я подтверждаю, что лично ознакомился с{" "}
                <Link to={"#"}>
                  Положением об обработке персональных данных НИУ ВШЭ
                </Link>
                , вправе предоставлять свои персональные данные и давать
                согласие на их обработку.
              </Typography>
            </Box>
            {formik.touched.accept && formik.errors.accept ? (
              <Typography className={styles.error}>
                {formik.errors.accept}
              </Typography>
            ) : null}
            <Button
              variant="contained"
              className={styles.btn}
              onClick={(e) => formik?.submitForm(e)}
            >
              Отправить
            </Button>
          </Form>
        )}
      </Formik>
      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactUs;
