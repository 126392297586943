import React, { useState } from "react";
import { Box, Container, Grid2, Typography, Pagination } from "@mui/material";
import useStyles from "./styles";
import { useGetPostsByTypeQuery } from "../../features/postApi";
import { useLocation, useNavigate } from "react-router-dom";

const List = ({ type }) => {
  const classes = useStyles();
  const { data = [] } = useGetPostsByTypeQuery(type); // Default data to an empty array
  const location = useLocation();
  const language = location.pathname.split("/")[1] || "en";
  const navigate = useNavigate();
  // Pagination state
  const [page, setPage] = useState(1);
  const itemsPerPage = 9; // 3 rows * 3 components per row = 9 items per page

  // Supported image extensions
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];

  // Helper function to filter files by image extension
  const isImage = (file) => {
    return imageExtensions.some((ext) =>
      file?.uuid?.toLowerCase()?.endsWith(ext)
    );
  };

  // Filtered data to include only posts with valid image files
  const filteredData = data.map((post) => ({
    ...post,
    files: post.files?.filter(isImage), // Filter the files array for image extensions
  }));

  // Paginated data
  const paginatedData = filteredData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Handle pagination
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container className={classes.root}>
      <Grid2 container spacing={3}>
        {paginatedData.map((post, index) => (
          <Grid2 item xs={12} sm={6} md={4} key={index}>
            <Box className={classes.card}>
              <div className={classes.imageDiv}>
                {post?.files?.[0] ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/file/download/${post?.files?.[0]?.uuid}`}
                    alt={post?.title?.[language] || "Post Image"}
                    className={classes.image}
                  />
                ) : (
                  <Typography className={classes.noImageText}>
                    No Image Available
                  </Typography>
                )}
              </div>
              <Typography
                onClick={() => {
                  navigate(`/${language}/${type}/${post?.id}`);
                }}
                className={classes.title}
              >
                {post?.title?.[language] || "Untitled Post"}
              </Typography>
            </Box>
          </Grid2>
        ))}
      </Grid2>
      {/* Pagination aligned above the footer */}
      <Box className={classes.paginationBox}>
        <Pagination
          count={Math.ceil(filteredData.length / itemsPerPage)} // Total pages
          page={page}
          onChange={handleChange}
          size="medium"
        />
      </Box>
    </Container>
  );
};

export default List;
