// src/api/menuApi.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base query with the base URL
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || "http://localhost:7999/api",
});

// Define the API slice
export const menuApi = createApi({
  reducerPath: "menuApi",
  baseQuery,
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: () => ({
        url: "menuItem/tree",
        method: "GET",
      }),
      transformResponse: (response) => response?.menus,
    }),
  }),
});

// Export the hook for the `getMenu` query
export const { useGetMenuQuery } = menuApi;

// Export the reducer and middleware for store configuration
export const { reducerPath, reducer, middleware } = menuApi;
