import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    height: 290,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  media: {
    width: "100%",
    height: 200,
    objectFit: "cover",
  },
  content: {
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    border: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    fontSize: `${theme.globals.fontSize.m}px!important`,
    marginBottom: theme.spacing(0.5),
    textDecoration: "underline",
    cursor: "pointer",
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: `${theme.globals.fontSize.sm}px!important`,
  },
}));

export default useStyles;
