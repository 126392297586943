import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base query with the base URL
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || "http://localhost:7999/api",
});

// Define the API slice
export const sliderApi = createApi({
  reducerPath: "sliderApi",
  baseQuery,
  endpoints: (builder) => ({
    getSlider: builder.query({
      query: (sliderType) => ({
        url: `post`,
        method: "GET",
        params: {
          type: "sliders",
          sliderType,
        },
      }),
      // This function could transform the response if needed
      transformResponse: (response) => {
        return response?.posts;
      },
      // Configure caching based on the sliderType
      providesTags: (result, error, sliderType) => [
        { type: "Slider", id: sliderType },
      ],
    }),
  }),
});

// Export the hook for the `getSlider` query
export const { useGetSliderQuery } = sliderApi;

// Export the reducer and middleware for store configuration
export const { reducerPath, reducer, middleware } = sliderApi;
