// src/App.jsx
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import store from "./store";
import AppRouter from "./AppRouter"; // Import the AppRouter component
import { StyledEngineProvider } from "@mui/material";

function App() {
  return (
    <Suspense fallback={<>loading</>}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <Router>
            <AppRouter /> {/* AppRouter is now within the Router context */}
          </Router>
        </Provider>
      </StyledEngineProvider>
    </Suspense>
  );
}

export default App;
