import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
  },
}));

export default useStyles;
