import React from "react";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

const NewsCard = ({ title, date, imageUrl, dest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  console.log({ dest });

  return (
    <div className={classes.root}>
      <img src={imageUrl} alt={title} className={classes.media} />
      <div className={classes.content}>
        <Typography
          onClick={() => {
            if (dest.startsWith("http://") || dest.startsWith("https://")) {
              // Full URL - navigate directly
              window.location.href = dest;
            } else {
              // Relative path - navigate with `navigate`
              navigate(dest);
            }
          }}
          variant="h5"
          component="div"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant="body2" className={classes.date}>
          {date}
        </Typography>
      </div>
    </div>
  );
};

export default NewsCard;
