import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  searchInput: {
    width: "100%",
    maxWidth: 300,
  },
  breadcrumb: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  mainTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  secondTitle: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    fontWeight: `${theme.globals.fontWeight.l}!important`,
  },
  note: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  codeBlock: {
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5 !important",
    overflow: "auto",
    fontFamily: "Monospace",
  },
}));

export default useStyles;
