import React from "react";
import { CircularProgress, Box } from "@mui/material";
import useStyles from "./styles"; // Import the styles
import { useSelector } from "react-redux";
export const Spinner = ({ fullPage = false }) => {
  const classes = useStyles();
  const isFullPageLoading = useSelector(
    (state) => state.loading.isFullPageLoading
  ); // Get global full-page loading state

  // Only render full-page spinner if fullPage is true and global loading state is true
  if (fullPage && !isFullPageLoading) return null;
  return (
    <Box className={fullPage ? classes.root : classes.blockRoot}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
