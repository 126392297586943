import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import GrayCard from "../../common/grayCard";
import useStyles from "./styles";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

const Modules = ({ data }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (url) => {
    navigate(url);
  };

  // Determine the language from the URL path
  const language = location.pathname.split("/")[1] || "en";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides in a row
    slidesToScroll: 1,
    prevArrow: (
      <button className={styles.slickPrev}>
        <img
          src={"/images/news/left.png"}
          alt="Previous"
          style={{ width: "18px", height: "33px" }}
        />
      </button>
    ),
    nextArrow: (
      <button className={styles.slickNext}>
        <img
          src={"/images/news/right.png"}
          alt="Next"
          style={{ width: "18px", height: "33px" }}
        />
      </button>
    ),
  };

  return (
    <Box className={styles.container}>
      <Typography
        onClick={() => {
          navigate(`/${language}/category/model`);
        }}
        className={styles.title}
        sx={{ cursor: "pointer" }}
      >
        {language === "en" ? "Available Modules" : "Размещаемые модули"}
      </Typography>
      <Slider {...settings} className={styles.slider}>
        {data.map((module, index) => (
          <div
            className={styles.root}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            key={index}
          >
            <GrayCard handleClick={handleClick} item={module} widther={true} />
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default Modules;
