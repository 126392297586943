// features/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "./userApi"; // Adjust the import path

const initialState = {
  user: null,
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token)); // Changed to store token as a string
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.updateUser.matchFulfilled,
      (state, action) => {
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      }
    );
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
