import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    paddingBottom: "64px",
    paddingTop: "24px",
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  title: {
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    fontSize: `${theme.globals.fontSize.g}px!important`,
    color: `${theme.globals.colors.black}!important`,
  },
  subTitle: {
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    fontSize: `${theme.globals.fontSize.g - 6}px!important`,
    color: `${theme.globals.colors.black}!important`,
  },
  label: {
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    fontSize: `${theme.globals.fontSize.m + 4}px!important`,
    color: `${theme.globals.colors.black}!important`,
    marginBottom: "8px",
  },
  form: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
  },
  input: {
    height: "42px",
    width: "75%",
    "& .MuiInputBase-root .MuiInputBase-input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      padding: "11px",
    },
    "& .MuiInputBase-root": {
      height: "36px",
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      height: "42px",
      borderRadius: "12px",
      width: "100%",
    },
  },
  autoSize: {
    borderRadius: "12px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    minWidth: "75%",
    padding: "14px",
    resize: "vertical",
  },
  checkText: {
    maxWidth: "74%",
    "& a": {
      color: "#b2d7ed",
    },
  },
  checkBox: {
    paddingTop: "0px!important",
  },
  btn: {
    marginLeft: "16px!important",
    backgroundColor: `${theme.globals.colors.darkBlue}!important`,
    height: "42px",
    width: "118px",
    borderRadius: "12px!important",
  },
  error: {
    color: "#d32f2f",
    fontSize: `${theme.globals.fontSize.xxs}px!important`,
  },
}));

export default useStyles;
