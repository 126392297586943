import React, { useEffect } from "react";
import { Container, Box, Button, Typography } from "@mui/material";
import { useGetMenuQuery } from "../../../features/menuSlice";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMenuLoadingComplete } from "../../../features/spinnerSlice";

export const Footer = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = location.pathname.split("/")[1] || "en";

  const { data: menus, error, isLoading } = useGetMenuQuery();

  if (isLoading) return <p>{t("FOOTER.LOADING")}</p>;
  if (error) return <p>{t("FOOTER.ERROR_LOADING")}</p>;

  const footerMenu = menus?.[1];
  const firstLinks = footerMenu?.children.slice(0, 3) || [];
  const secondLinks = footerMenu?.children.slice(3, 6) || [];
  const thirdLinks = footerMenu?.children.slice(6, 9) || [];

  const contactItems = [
    {
      type: "email",
      value: t("FOOTER.EMAIL_VALUE"),
      alt: t("FOOTER.EMAIL_ALT"),
      href: `mailto:${t("FOOTER.EMAIL_VALUE")}`,
    },
    {
      type: "Phone",
      value: t("FOOTER.PHONE_VALUE"),
      alt: t("FOOTER.PHONE_ALT"),
      href: `tel:${t("FOOTER.PHONE_VALUE")}`,
    },
    {
      type: "location",
      value: t("FOOTER.LOCATION_VALUE"),
      alt: t("FOOTER.LOCATION_ALT"),
      href: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        t("FOOTER.LOCATION_VALUE")
      )}`,
    },
  ];

  const renderLink = (item) => {
    const link = item?.link;

    return (
      <Button
        key={item.id}
        variant="text"
        onClick={() => {
          if (link.startsWith("http://") || link.startsWith("https://")) {
            // Navigate to full URL
            window.location.href = link;
          } else {
            // Navigate to relative path with language
            navigate(`/${language}${link}`);
          }
        }}
        className={styles.link}
      >
        {item.title[language]}
      </Button>
    );
  };

  const renderContactItem = (item) => (
    <Box className={styles.linkBox} key={item.type}>
      <img src={`/images/${item.type}.svg`} alt={item.alt} />
      <a
        href={item.href}
        target={item.type === "location" ? "_blank" : "_self"}
        rel="noopener noreferrer"
        className={styles.link}
      >
        {item.value}
      </a>
    </Box>
  );

  return (
    <Box className={styles.root}>
      <Container maxWidth="xl" className={styles.container}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            {firstLinks.map((item) => renderLink(item))}
          </Box>
          <Typography className={styles.rights}>
            ©{" "}
            {t("FOOTER.ALL_RIGHTS_RESERVED", {
              year: new Date().getFullYear(),
            })}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {secondLinks.map((item) => renderLink(item))}
        </Box>
        <Box display="flex" flexDirection="column">
          {thirdLinks.map((item) => renderLink(item))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          paddingTop="24px"
          paddingBottom="24px"
        >
          {contactItems.map((item) => renderContactItem(item))}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
