import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: "0px !important",
      marginTop: "20px",
    },
    searchTitle: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      fontSize: `${theme.globals.fontSize.s}px !important`,
      fontWeight: `${theme.globals.fontWeight.l} !important`,
      margin: "5px !important",
    },
    searchBox: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      border: "1px #cccccc solid",
      borderRadius: "14px",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          margin: "5px",
        },
      },
    },
    searchIcon: { color: "#878789", margin: "10px" },
  };
});

export default useStyles;
