import React, { useState } from "react";
import { Container, Grid2, Typography } from "@mui/material";
import useStyles from "./styles.js";

import Search from "../../components/basic/search/index.js";
import News from "../../components/basic/news/index.js";
import {
  useGetPostsByTypeQuery,
  useGetPostByIdQuery,
  useGetPostByAliasQuery,
} from "../../features/postApi.js";
import { useParams } from "react-router-dom";
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css"; // Import slick-carousel CSS
import "slick-carousel/slick/slick-theme.css";
import DOMPurify from "dompurify"; // Import DOMPurify for sanitizing HTML

const Basic = ({}) => {
  const classes = useStyles();
  const { alias, lang } = useParams();
  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:7999";
  console.log({ alias, lang });

  // Fetch a single post by ID
  const {
    data: singlePost,
    isLoading: loadingSinglePost,
    error: singlePostError,
  } = useGetPostByAliasQuery({
    alias,
  });

  // Slick slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Supported image extensions
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];

  // Helper function to filter files by image extension
  const isImage = (file) => {
    return imageExtensions.some((ext) =>
      file?.uuid?.toLowerCase()?.endsWith(ext)
    );
  };

  // Filter images from post.files
  const images = singlePost?.files?.filter(isImage) || []; // Fallback to an empty array if no valid images

  // Safely sanitize the description with DOMPurify
  const sanitizedDescription = DOMPurify.sanitize(
    singlePost?.description?.[lang]
  );

  return (
    <Container className={classes.root}>
      <Grid2 container spacing={2}>
        {/* Main Section */}
        <Grid2 size={{ xs: 12, md: 12 }}>
          <Typography className={classes.mainTitle}>
            {singlePost?.title?.[lang]}
          </Typography>
          {/*          <div className={classes.slider}>
            
            <Slider {...sliderSettings}>
              {images.map((file) => (
                <div key={file.uuid}>
                  <img
                    src={`${baseUrl}/file/download/${file.uuid}`}
                    alt={`Image ${file.uuid}`}
                    className={classes.media}
                  />
                </div>
              ))}
            </Slider>
          </div> */}
          <Typography
            className={classes.mainText}
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </Grid2>

        {/* Sidebar */}
      </Grid2>
    </Container>
  );
};

export default Basic;
