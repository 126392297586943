import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& *": {
      fontFamily: `${theme.globals.fontFamily}!important`,
    },
  },
  title: {
    fontWeight: `${theme.globals.fontWeight.l}!important`,
    fontSize: `${theme.globals.fontSize.xxl}px!important`,
    color: `${theme.globals.colors.lightGray}!important`,
  },
  text: {
    fontSize: `${theme.globals.fontSize.s}px!important`,
    "& a": {
      color: `${theme.globals.colors.darkBlue}!important`,
      textDecoration: "none",
    },
  },
}));

export default useStyles;
