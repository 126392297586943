import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxWidth: "none !important",
      width: "90% !important",
    },
    media: {
      width: "100%",
      height: 500,
      objectFit: "cover",
    },
    mainText: {
      fontFamily: `${theme.globals.fontFamily} !important`,
      color: "#4B4B4F",
    },

    mainTitle: {
      marginTop: "10px !important",
      marginBottom: "10px !important",
      fontFamily: `${theme.globals.fontFamily} !important`,
      fontSize: `24px !important`,
      fontWeight: `${theme.globals.fontWeight.l} !important`,
      textAlign: "center !important", // Centralize the text
    },
  };
});
export default useStyles;
