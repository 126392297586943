import { Typography, Box } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import GrayCard from "../../common/grayCard";
import { useNavigate, useLocation } from "react-router-dom";

const Info = ({ data }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the language from the URL path
  const language = location.pathname.split("/")[1] || "en";

  // Handle navigation when clicking on the Box component
  const handleClick = (url) => {
    navigate(url);
  };

  return (
    <Box className={styles.root} sx={{ cursor: "pointer" }}>
      <Typography
        onClick={() => {
          navigate(`/${language}/category/information`);
        }}
        className={styles.title}
      >
        {language === "en"
          ? "Information for Developers"
          : "Информация для разработчиков"}
      </Typography>
      <Box className={styles.container}>
        {data?.map((item) => (
          <GrayCard handleClick={handleClick} key={item?.id} item={item} />
        ))}
      </Box>
    </Box>
  );
};

export default Info;
