export const defaultTheme = {
  id: 1,
  name: "default Theme",
  isActive: true,
  createdAt: "2024-10-23T13:41:49.211Z",
  updatedAt: "2024-10-23T13:41:49.211Z",
  elements: {
    type: "light",
    direction: "ltr",
    fontFamily: "HseSans",
    fontWeight: { xs: 200, s: 400, m: 500, l: 600, ll: 700 },
    fontSize: {
      xxs: 12,
      xs: 13,
      sm: 14,
      s: 16,
      m: 18,
      g: 36,
      l: 45,
      xxl: 48,
    },
    colors: {
      white: "#fff",
      darkBlue: "#234A9B",
      black: "#0F0F14",
      lightGray: "#4B4B4F",
      gray: "#E6E6E6",
    },
  },
};

export const basicTheme = {
  isDark: false,
  currentTheme: defaultTheme,
  themeList: [defaultTheme],
  isRTL: false,
};
