import { Container, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useStyles from "./styles";
import Left from "../../components/download/left/index";
import Middle from "../../components/download/middle/index";
import { useGetPostByIdQuery } from "../../features/postApi";

const Download = ({ type }) => {
  const classes = useStyles();

  // Extract the ID from the URL and set the type as 'model'
  const { id } = useParams();
  const location = useLocation();
  const language = location.pathname.split("/")[1] || "en";

  // State for storing extracted headings with IDs
  const [headings, setHeadings] = useState([]);

  // Fetch the post data
  const { data: post, isLoading, isError } = useGetPostByIdQuery({ id, type });

  // Utility function to format camelCase to readable text
  const formatCamelCaseToReadable = (text) => {
    if (!text) return "";
    return text
      .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
      .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter
  };

  // Extract IDs from the description on data load
  useEffect(() => {
    if (post?.description?.[language]) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        post.description[language],
        "text/html"
      );

      // Extract elements with IDs and format them for navigation
      const extractedHeadings = Array.from(doc.querySelectorAll("[id]")).map(
        (el) => ({
          id: el.id,
          text: formatCamelCaseToReadable(el.id),
        })
      );

      console.log("Extracted Headings:", extractedHeadings);

      // Update state with extracted headings
      setHeadings(extractedHeadings);
    }
  }, [post, language]);

  // Handle loading and error states
  if (isLoading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box className={classes.errorContainer}>
        <Typography variant="h6" color="error">
          Failed to load the post.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        {/* Left Sidebar */}
        <Left extractedHeadings={headings} />

        {/* Middle Section */}
        <Middle post={post} isError={isError} isLoading={isLoading} />
      </Container>
    </Box>
  );
};

export default Download;
