import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from "../../newsCard";
import useStyles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";

const News = ({ data }) => {
  const styles = useStyles();
  const location = useLocation();

  // Determine the language from the URL path
  const language = location.pathname.split("/")[1] || "en";
  const navigate = useNavigate();
  // Base URL based on environment
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:7999/api"
      : "/api";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at once in a row
    slidesToScroll: 1,
    prevArrow: (
      <button className={styles.slickPrev}>
        <img
          src={"/images/news/left.png"}
          alt="Previous"
          style={{ width: "18px", height: "33px" }}
        />
      </button>
    ),
    nextArrow: (
      <button className={styles.slickNext}>
        <img
          src={"/images/news/right.png"}
          alt="Next"
          style={{ width: "18px", height: "33px" }}
        />
      </button>
    ),
  };

  return (
    <Box className={styles.container}>
      <Typography
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/${language}/category/news`);
        }}
        className={styles.title}
      >
        Новости проекта
      </Typography>
      <Slider {...settings} className={styles.slider}>
        {data.map((slide, index) => {
          // Find the appropriate image URL based on language and publishMode
          const image = slide.files.find(
            (image) =>
              (language === "en" &&
                (image.publishMode === 1 || image.publishMode === 4)) ||
              (language === "ru" &&
                (image.publishMode === 2 || image.publishMode === 4))
          );

          // Check if an image was found
          const imageUrl = image
            ? `${baseUrl}/file/download/${image.uuid}`
            : "";

          return (
            <div
              className={styles.root}
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px", // Add padding for spacing between slides
              }}
              key={index}
            >
              <Card
                title={slide.title?.[language] || "Default Title"}
                date={slide.date}
                imageUrl={imageUrl}
                dest={JSON.parse(slide?.extraData)?.link?.[language]}
              />
            </div>
          );
        })}
      </Slider>
    </Box>
  );
};

export default News;
