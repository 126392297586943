import React from "react";
import { Typography, Box } from "@mui/material";
import DOMPurify from "dompurify";
import clsx from "clsx";
import { Navigate, useLocation } from "react-router-dom";
import useStyles from "./styles";

const GrayCard = ({ item, widther, handleClick }) => {
  const styles = useStyles();
  const location = useLocation();

  // Determine the language from the URL path
  const language = location.pathname.split("/")[1] || "en";

  // Base URL for images based on environment
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:7999/api"
      : "/api";

  const image = item.files.find(
    (image) =>
      (language === "en" &&
        (image.publishMode === 1 || image.publishMode === 4)) ||
      (language === "ru" &&
        (image.publishMode === 2 || image.publishMode === 4))
  );

  // Safely sanitize the description using DOMPurify
  const sanitizedDescription = item?.description?.[language]
    ? DOMPurify.sanitize(item.description[language])
    : "";

  return (
    <Box
      key={item.id}
      onClick={() => {
        handleClick(JSON.parse(item?.extraData)?.link?.[language]);
      }}
      className={clsx(styles.info, widther ? styles.widtherInfo : "")}
    >
      <Box className={styles.image}>
        <img
          src={`${baseUrl}/file/download/${image?.uuid}`}
          alt={item?.label?.[language] || "Image"}
        />
      </Box>
      <Typography
        className={styles.label}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 2, // Limit to 3 lines
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
      >
        {item?.title?.[language] || "Default Label"}
      </Typography>
      {item?.sliderType === "model" && sanitizedDescription && (
        <Typography
          className={styles.summary}
          component="div"
          dangerouslySetInnerHTML={{
            __html: sanitizedDescription,
          }}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 1, // Limit to 3 lines
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        />
      )}
    </Box>
  );
};

export default GrayCard;
